import { Link } from "react-router-dom";
export default function Publication() {
  return (
   <>
        <div className=" inner-heading">
            <div className="container sml-container">
            <h2 className="medium-heading text-black">Publications</h2>
            </div>
        </div>
       
        <div className="publication-body">
            <div className="container mb-4">
                <div className="d-flex justify-content-center">
                    <ul className="bullet-list">
                    <li>Islam, M.T., Zhou, Z., Zou, J., Kapp, D., Liao, J. and and Xing, L.,“ Revealing
hidden patterns in deep neural network feature space continuum via manifold learning”,
2023, in press, Nature Communications.</li>
                    <li>Islam, M.T. and Xing, L.,“Cartography of genomic interactions enables deep analysis of
single-cell expression data”, 2023, Nature Communications, 14(1), p.679.</li>
                    <li>Islam, M.T. and Xing, L.,“Leveraging cell-cell interactions for high-performance spatial
and temporal cellular mappings from gene expression data”, 2023, Patterns, Cell Press,
p.100840.</li>
                    <li>Islam, M.T., Wang, J., Ren, H., Li, X., Khuzani, M., Yu, L., Shen, L., Zhao, W., and
Xing, L.,“ Leveraging data-driven self-consistency for high-fidelity gene expression
recovery”, 2022, Nature Communications, 13(1), pp.7142.</li>
                    <li>Islam, M.T. and Xing, L.,“A data-driven dimensionality-reduction algorithm for the
exploration of patterns in biomedical data”, 2021, Nature Biomedical Engineering, 5(6),
pp.624-635.</li>
                    <li>Liu, J., Islam, M.T., Sang, S., Qiu, L., and Xing, L., “Biology-aware mutation-based
deep learning for outcome prediction of cancer immunotherapy with immune checkpoint
inhibitors”, 2023, npj Precision Oncology, 7 (1), pp.117.</li>
                    <li>Sang, S., Zhou, Y., Islam, M.T. and Xing, L.,“Small-object sensitive segmentation using
across feature map attention”, 2023, IEEE Transactions on Pattern Analysis and Machine
Intelligence, 45 (5), pp.6289-6306.</li>
                    
                    </ul>
                </div>
            </div>
            
           
        </div>
   </>
  );
}
